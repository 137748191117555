input {
    margin-left: 8px;
}

form {
    padding: 16px;
}

input[type=submit] {
    margin-top: 14px;
    margin-left: 0px;
}

.group {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.input-container {
    margin-left: 12px;
}