body {
    background-color: rgba(66, 146, 198, 0.1);
}

.react-tabs__tab-list {
    background-color: white;
}

.dashboardContainer {
    padding-bottom: 20px;
}

.dashboardContainer .DateRangePicker {
    margin-top: 10px;
}

.dashboardContainer .DateRangePicker__CalendarSelection {
    background-color: #4292c6;
    border: 1px solid #4292c6;
}

.dashboardContainer .DateRangePicker__CalendarHighlight--single {
    border: 1px solid #4292c6;
}

.dashboardHeader {
    padding: 16px 0;
}